<template>
    <b-row class="data-label">
        <b-col cols="6" class="text-left ml-0 mr-0 headLabel">{{labelName}}</b-col>
        <b-col cols="6" :class="labelRight == true ? 'text-right' : 'text-left'">{{data == null || data == '' ? 'N/A' : data}}</b-col>
    </b-row>
</template>

<script>
export default {
    name: 'data-label',
    props: {
        labelName: String,
        data: String,
        labelRight: Boolean
    },
    methods: {
    }
}
</script>

