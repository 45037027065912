import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import "./components/smallComponents/index"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.scss'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)

Vue.filter('dateTimeFilter', function (value) {
  if (!value) return ''
  let localeValue = new Date(value)
  let response = localeValue.getDate().toString().padStart(2, '0')+ '/' + (localeValue.getMonth()+1).toString().padStart(2,'0') + '/' + localeValue.getFullYear() + ' ' + localeValue.getHours().toString().padStart(2, '0') + ':' + localeValue.getMinutes().toString().padStart(2, '0')
  return response
})
Vue.filter('dateFilter', function (value) {
  if (!value) return ''
  let localeValue = new Date(value)
  let response = localeValue.getDate().toString().padStart(2, '0')+ '/' + (localeValue.getMonth()+1).toString().padStart(2,'0') + '/' + localeValue.getFullYear() 
  return response
})
export const bus = new Vue();

fetch('/config.json')
  .then(res => res.json())  
  .then(config => {
    new Vue({
      data: () => ({
        urlConfig : config
      }),
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })