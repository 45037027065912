import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '',
    userIdentifier: null,
    user: null,
    selectedSurveys: null,
    selectedJobCard: null,
    selectedQuote: null,
    selectedChecklist: null,
    selectedContact: null,
    selectedProduct: null,
    contractTypes: [],
    selectedUser: null,
    completedChecklist: null,
    selectedTrigger: {active: false, clientContactNumber: null, clientContactNumberAlt: null, clientEmail:null, company: {description: null}, locationId: null, checkLists: [], technitianName: '', technitianId: null},
    selectedSurvey: null,
    selectedScheduledChecklist: null,
    selectedPpeRequest: null,
    selectedTimesheet: null,
    secondaryRequestTypes: [],
    selectedStock: null,

    //request objects
    loginRequest: null,
    resetPasswordRequest: null,

    ssrSearchRequest: null,
    questionForCategoryRequest: null,
    ssrAddRequest: null,
    jobcardSearchRequest: null,
    quoteSearchRequest: null,
    quoteCreateRequest : null,
    productSearchRequest: null,
    productCreateRequest: null,
    checklistSearchRequest: null,
    checklistCreateRequest: null,
    newCategoryRequest: null,
    contactSearchRequest: null,
    contactCreateRequest: null,
    userSearchRequest: null,
    userCreateRequest: null,
    userPasswordRequest: null,
    clientContactSearchRequest: null,
    technicianGroupSearchRequest: null,
    deleteCategoryRequest: null,
    searchClientByCompanyRequest: null,
    createDocumentRequest: null,
    searchCompaniesRequest: null,
    searchLocationByCompanyRequest: null,
    locationCreateRequest: null,
    userChecklistRequest: null,
    jobcardChecklistRequest: null,
    getChecklistAnswerRequest: null,
    searchAclTriggerRequest: null,
    searchSurveysRequest: null,
    createSurveyRequest: null,
    searchXLogRequest: null,
    searchEventRequest: null,
    downloadSurveyRequest: null,
    downloadDocumentForJobCardRequest: null,
    filename: null,
    uploadAnswerImageRequest: null,
    checklistScheduledSearchRequest: null,
    deleteScheduleChecklistRequest: null,
    scheduleId: null,
    newPpeRequestRequest: null,
    searchPpeRequestsRequest: null,
    ppeRequestIdRequest: null,
    downloadRequest: null,
    searchUpdateAvailableItemsRequest: null,
    documentId: null,
    surveyKey: null,
    saveSurveyRequest: null,
    quoteSummaryRequest: null,
    deleteTimesheetEntryRequest: null,
    saveTimesheetRequest: null,
    getSummaryRequest: null,
    technicianSearchRequest: null,
    getForSubmissionRequest: null,
    searchSubmissionsRequest: null,
    completeTimesheetRequest: null,
    requestTypesRequest: null,
    regionListRequest:null,
    officeListRequest:null,
    sendOtpToEmailRequest: null,
    sendOtpToClientRequest: null,
    copyQuoteId: null,
    travelItem: null,
    removetravelRequest: null,
    mailList: null
  },
  mutations: {
    setAddServeysRequest: (state, payload) => {state.selectedSurveys = payload},
    setSelectedJobCard: (state, payload) => {state.selectedJobCard = payload},
    setSelectedQuote: (state, payload) => {state.selectedQuote = payload},
    setIsQuoteEditable: (state, payload) => {state.isQuoteEditable = payload},
    setSelectedChecklist: (state, payload) => {state.selectedChecklist = payload},
    setScheduleChecklistId: (state, payload) => {state.scheduleId = payload},
    setSelectedContact: (state, payload) => {state.selectedContact = payload},
    setSelectedProduct: (state, payload) => {state.selectedProduct = payload},
    setSelectedUser: (state, payload) => {state.selectedUser = payload},
    setSelectedAclTrigger: (state, payload) => {state.selectedTrigger = payload},
    setSelectedSurvey: (state, payload) => {state.selectedSurvey = payload},
    setSelectedScheduledChecklist: (state, payload) => {state.selectedScheduledChecklist = payload},
    setSelectedPpeRequest: (state, payload) => {state.selectedPpeRequest = payload},
    secondaryRequestTypeClear: (state, payload) => {state.secondaryRequestTypes = payload},
    setSelectedTimesheet: (state, payload) => {state.selectedTimesheet = payload},
    setSelectedStock:(state, payload) => {state.selectedStock = payload},
    

    //set requests and the likes
    setApiUrl: (state, payload) => {
      state.baseUrl = payload
      localStorage.setItem('apiUrl', payload)
    },

    setLoginRequest: (state, payload) => {state.loginRequest = payload},
    setResetPasswordRequest: (state, payload) => {state.resetPasswordRequest = payload},

    setSsrSearchRequest: (state, payload) => {state.ssrSearchRequest = payload},
    setQuestionForCategoryRequest: (state, payload) => {state.questionForCategoryRequest = payload},
    setQuoteCreateRequest: (state, payload) => {state.quoteCreateRequest = payload},
    setSsrAddRequest: (state, payload) => {state.ssrAddRequest = payload},
    setJobcardSearchRequest: (state, payload) => {state.jobcardSearchRequest = payload},
    setQuoteSearchRequest: (state, payload) => {state.quoteSearchRequest = payload},
    setProductSearchRequest: (state, payload) => {state.productSearchRequest = payload},
    setProductCreateRequest: (state, payload) => {state.productCreateRequest = payload},
    setChecklistSearchRequest: (state, payload) => {state.checklistSearchRequest = payload},
    setChecklistCreateRequest: (state, payload) => {state.checklistCreateRequest = payload},
    setNewCategoryRequest: (state, payload) => {state.newCategoryRequest = payload},
    setContactSearchRequest: (state, payload) => {state.contactSearchRequest = payload},
    setContactCreateRequest: (state, payload) => {state.contactCreateRequest = payload},
    setUserSearchRequest: (state, payload) => {state.userSearchRequest = payload},
    setUserCreateRequest: (state, payload) => {state.userCreateRequest = payload},
    setUserPasswordRequest: (state, payload) => {state.userPasswordRequest = payload},
    setClientContactSearchRequest: (state, payload) => {state.clientContactSearchRequest = payload},
    // setTechnicianGroupSearchRequest: (state, payload) => {state.technicianGroupSearchRequest = payload},
    setDeleteCategoryRequest: (state, payload) => {state.deleteCategoryRequest = payload},
    setSearchCompaniesRequest: (state, payload) => {state.searchCompaniesRequest = payload},
    setSearchClientByCompanyRequest: (state, payload) => {state.searchClientByCompanyRequest = payload},
    setCreateDocumentRequest: (state, payload) => {state.createDocumentRequest = payload},
    setSearchLocationByCompanyRequest: (state, payload) => {state.searchLocationByCompanyRequest = payload},
    setLocationCreateRequest: (state, payload) => {state.locationCreateRequest = payload},
    setUserChecklistRequest: (state, payload) => {state.userChecklistRequest = payload},
    setJobcardChecklistRequest: (state, payload) => {state.jobcardChecklistRequest = payload},
    setGetChecklistAnswerRequest: (state, payload) => {state.getChecklistAnswerRequest = payload},
    setCompletedChecklist: (state, payload) => {state.completedChecklist = payload},
    setSearchAclTriggerRequest: (state, payload) => {state.searchAclTriggerRequest = payload},
    setSearchXLogRequest: (state, payload) => {state.searchXLogRequest = payload},
    setSearchSurveysRequest: (state, payload) => {state.searchSurveysRequest = payload},
    setCreateSurveyRequest: (state, payload) => {state.createSurveyRequest = payload},
    setSearchEventRequest: (state, payload) => {state.searchEventRequest = payload},
    setDownloadDocumentForJobCardRequest: (state, payload) => {state.downloadDocumentForJobCardRequest = payload},
    setDownloadDocumentName: (state, payload) => {state.filename = payload},
    setDownloadSurveyRequest: (state, payload) => {state.downloadSurveyRequest = payload},
    setUploadAnswerImageRequest: (state, payload) => {state.uploadAnswerImageRequest = payload},
    setChecklistScheduledSearchRequest: (state, payload) => {state.checklistScheduledSearchRequest = payload},
    setDeleteScheduleChecklistRequest: (state, payload) => {state.deleteScheduleChecklistRequest = payload},
    setDocumentId: (state, payload) => {state.documentId = payload},
    setSurveyKey: (state, payload) => {state.surveyKey = payload},
    setSaveSurveyRequest: (state, payload) => {state.saveSurveyRequest = payload},
    setNewPpeRequestRequest: (state, payload) => {state.newPpeRequestRequest = payload},
    setSearchPpeRequestsRequest: (state, payload) => {state.searchPpeRequestsRequest = payload},
    setPpeRequestIdRequest: (state, payload) => {state.ppeRequestIdRequest = payload},
    setDownloadRequest: (state, payload) => {state.downloadRequest = payload},
    setSearchUpdateAvailableItemsRequest: (state, payload) => {state.searchUpdateAvailableItemsRequest = payload},
    setTravelItemRequest:(state, payload) => {state.travelItem = payload},
    setQuoteSummaryRequest: (state, payload) => {state.quoteSummaryRequest = payload},
    setSaveTimesheetRequest: (state, payload) => {state.saveTimesheetRequest = payload},
    setLoadDateRequest: (state, payload) => {state.loadDateRequest = payload},
    setDeleteTimesheetEntryRequest: (state, payload) => {state.deleteTimesheetEntryRequest = payload},
    setGetSummaryRequest: (state, payload) => {state.getSummaryRequest = payload},
    setTechnicianSearchRequest: (state, payload) => {state.technicianSearchRequest = payload},
    setGetForSubmissionRequest: (state, payload) => {state.getForSubmissionRequest = payload},
    setSearchSubmissionsRequest: (state, payload) => {state.searchSubmissionsRequest = payload},
    setCompleteTimesheetRequest: (state, payload) => {state.completeTimesheetRequest = payload},
    setRequestTypesRequest: (state, payload) => {state.requestTypesRequest = payload},
    setGetRegionRequest: (state,payload) => {state.regionListRequest = payload},
    setGetOfficeRequest: (state,payload) => {state.officeListRequest = payload},
    setSendOtpToEmailRequest: (state,payload) => {state.sendOtpToEmailRequest = payload},
    setSendOtpFormToEmailRequest: (state,payload) => {state.sendOtpFormToEmailRequest = payload},
    setsendOtpToClientRequest: (state,payload) => {state.sendOtpToClientRequest = payload},
    setCopyQuoteIdRequest: (state,payload) => {state.copyQuoteId = payload},
    setJobCardIdRequest: (state,payload) => {state.ticketId = payload},
    removetravelRequest: (state,payload) => {state.removetravelRequest = payload},
    setMailListRequest:(state, payload) => {state.mailList = payload},
    
  },
  actions: {
    //log in screen
    login: ({state, dispatch}) => {
      let payload = state.loginRequest
      return new Promise((resolve, reject) =>{
        axios.post(state.baseUrl + 'Token/auth', payload)
        .then(response => {
          localStorage.setItem('jwt', response.data.value.securityToken)
          localStorage.setItem('user', response.data.value.user.name + " " + response.data.value.user.surname)
          localStorage.setItem('userEmail', response.data.value.user.email)
          localStorage.setItem('screenList', JSON.stringify(response.data.value.accessRights))
          state.user = response.data.value.user
          resolve(response)
        })
        .catch((err) => {
          dispatch('notificationService/addErrorMessage', {error: err.toString(), message:  'Username or password incorrect.'})
          reject()
        })
      })
    },
    
    sendRequestToResetPassword: ({state, dispatch}) => {
      let payload = state.resetPasswordRequest
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: localStorage.getItem('apiUrl') + 'Token/requestnewpassword',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        dispatch('notificationService/addSuccessMessage', 'Please check your email for further instructions')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message: 'Could not send request. Please contact your Administrator'})
        reject(err)
      })
    })
    },
    
    resetPassword: ({state, dispatch}) => {
      let payload = state.resetPasswordRequest
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: localStorage.getItem('apiUrl') + 'Token/setPassword',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not reset password. Please contact your Administrator'})
        reject(err)
      })
    })
    },

    //self service requests
    searchSelfServiceRequests: ({state, dispatch}) => {
      let payload = state.ssrSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'SelfServiceRequest/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    addSelfServiceRequest: ({state, dispatch}) => {
      let payload = state.ssrAddRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'SelfServiceRequest/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    searchCompanies: ({state, dispatch}) => {
      let payload = state.searchCompaniesRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Company/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
        };
        
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },

    getAllSurveys: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'survey/getall',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },	

    addSurveysToTicket: ({state, dispatch}) => {
      let payload = state.selectedSurveys

      //console.log(state.selectedJobCard.ticketId, payload);

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/addSurveys/'  + state.selectedJobCard.ticketId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
        };
        
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },

    searchLocationByCompany: ({state, dispatch}) => {

      if (state.searchLocationByCompanyRequest.companyId==null)
        return null;
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Location/getbycompany/' + state.searchLocationByCompanyRequest.companyId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    registerNewLocation: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Location/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.locationCreateRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue creating the location. Please refresh and try again'})
        reject(err)
      })
    })
    },
    
    searchClientByCompany: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'ClientContact/getbycompanyid/' + state.searchClientByCompanyRequest.companyID,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    uploadDocument: ({state, dispatch}) => {

      return new Promise((resolve, reject) =>{
        let payload = state.createDocumentRequest
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Document/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error: err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    deleteDocument: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{        
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Document/delete/' + state.documentId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error: err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    //job cards
    searchJobCards: ({state, dispatch}) => {
      let payload = state.jobcardSearchRequest

      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'JobCard/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    updateJobCard: ({state, dispatch}) => {
      let payload = state.selectedJobCard

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/update',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        
        if (response.data === false) {
          dispatch('notificationService/addErrorMessage',{error:  '', message: 'An error occured while trying to save the data'})
        }else{
          dispatch('notificationService/addSuccessMessage', 'Update was successful')
        }
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    getJobCard: ({state, dispatch}) => {

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'JobCard/get/'+ state.selectedJobCard.ticketId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        }
      };

      axios(callConfig)
      .then(response => {
        //console.log("response.data",response.data)
        state.selectedJobCard = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    getJobCardNotes: ({state, dispatch}) => {

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'JobCard/getTicketNotes/'+ state.selectedJobCard.ticketId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        }
      };
      axios(callConfig)
      .then(response => {
        state.selectedJobCard.notes = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

      getJobCardLabour: ({state, dispatch}) => {

        return new Promise((resolve, reject) =>{
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'jobCard/GetLabour/'+ state.selectedJobCard.ticketId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
        axios(callConfig)
      .then(response => {
        //console.log("Labour", response.data)
        state.selectedJobCard.labours = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    getAvailableMaterials: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'JobCard/GetAvailableMaterials',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    deleteTravel: ({state, dispatch}) => {
      
     
      console.log(state.removetravelRequest);

    //console.log('removeStockItem:' + ticketid +'/' + equipmentId);

    //let payload = state.removetravelRequest.equipmentId;

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/removetravel/' +state.removetravelRequest.ticketId + '/' + state.removetravelRequest.equipmentId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: {}
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue removing the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    sendOtpFormToEmail: ({state, dispatch}) => {
      let payload = state.jobcardSearchRequest
//console.log(state.baseUrl + 'JobCard/SendOtp')
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'JobCard/SendOtp',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {

              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    sendOtpToEmail: ({state, dispatch}) => {

      let payload = state.sendOtpToEmailRequest
//console.log("Payload ", payload)
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/SignProcessAsync',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload,
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    downloadSummaryReport:({state, dispatch}) =>{

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Jobcard/ExportTicketToPDF/'+ state.selectedJobCard.ticketId,
        headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue downloading the document. Please refresh and try again'})
          reject(err)
        })
      })
    },

    checkOtpForClient: ({state, dispatch}) => {
debugger
      let payload = state.sendOtpToClientRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/SignProcessAsync',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload,
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },

    getAvailableVehicles: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'JobCard/GetAvailableVehicles',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: []
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the vehicles. Please refresh and try again'})
        reject(err)
      })
    })
    },


    searchBootstock: ({state, dispatch}) => {
      return new Promise((resolve, reject) => {
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Technician/GetBootStockGrouped',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not get stock items. Please refresh and try again or contact system administrators.'})
          reject(err)
        })
      })
    },

    searchClientstock: ({state, dispatch}) => {
      return new Promise((resolve, reject) => {
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Technician/GetClientStockGrouped/' + state.selectedJobCard.clientId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not get stock items. Please refresh and try again or contact system administrators.'})
          reject(err)
        })
      })
    },

    // AssignStock: ({state, dispatch}) => {
    //   let payload = state.selectedStock

    //   return new Promise((resolve, reject) => {
    //     var callConfig = {
    //       method: 'post',
    //       url: state.baseUrl + 'jobcard/AssignStock',
    //       headers: {
    //         'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
    //         'Content-Type': 'application/json'
    //       },
    //       data: payload,
    //     };
      
    //     axios(callConfig)
    //     .then(response => {
    //       resolve(response)
    //     })
    //     .catch(err => {
          
    //       dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not assign stock. Please refresh and try again or contact system administrators.'})
    //       reject(err)
    //     })
    //   })
    // },

    AssignStockItem: ({state, dispatch}) => {
      let payload = state.selectedStock

      return new Promise((resolve, reject) => {
        var callConfig = {
          method: 'post',
          url: state.baseUrl + 'jobcard/AssignStockQty',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };
      
        axios(callConfig)
        .then(response => {
          console.log("response",response);
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not assign stock. Please refresh and try again or contact system administrators.'})
          reject(err)
        })
      })
    },


    RemoveStock: ({state, dispatch}) => {
      let payload = state.selectedStock

      return new Promise((resolve, reject) => {
        var callConfig = {
          method: 'post',
          url: state.baseUrl + 'jobcard/RemoveStock',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not assign stock. Please refresh and try again or contact system administrators.'})
          reject(err)
        })
      })
    },

    copyQuote: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'BudgetaryQuote/copy/' + state.copyQuoteId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'Could not copy quote. Please refresh and try again or contact system administrators.'})
        reject(err)
      })
    })
    },

    downloadDocumentForJobCard: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Document/download/'+ state.downloadDocumentForJobCardRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue downloading the document. Please refresh and try again'})
        reject(err)
      })
    })
    },

    downloadFileByName: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Document/downloadfile/'+ state.filename,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response.data.result)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue downloading the document. Please refresh and try again'})
        reject(err)
      })
    })
    },
    
    getChecklistResponse: ({state, dispatch}) => {
        return new Promise((resolve, reject) =>{
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/checklist/getResult/'+ state.selectedJobCard.ticketId + '/' + state.completedChecklist.checkListId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          }
        };
        
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },

    searchClients: ({state, dispatch}) => {
      let payload = state.clientContactSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'ClientContact/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },
    
    // searchTechnicianGroup: ({state, dispatch}) => {
    //   let payload = state.technicianGroupSearchRequest

    //   return new Promise((resolve, reject) =>{
    //     var callConfig = {
    //     method: 'post',
    //     url: state.baseUrl + 'Technician/searchGroup',
    //     headers: {
    //       'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
    //       'Content-Type': 'application/json'
    //     },
    //     data: payload
    //   };
      
    //     axios(callConfig)
    //     .then(response => {
    //       resolve(response)
    //     })
    //     .catch(err => {
    //       
    //       dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
    //       reject(err)
    //     })
    //   })
    // },

    searchTechnician: ({state, dispatch}) => {
      let payload = state.technicianSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Technician/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
      })
    },

    searchTechnicians: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Technician/getTechniciansByGroup/' + state.selectedJobCard.technicianGroupId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    searchTechniciansForTimesheet: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Technician/getTechniciansByGroup/' + state.technicianSearchRequest,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    searchPriority: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/getpriorities',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    searchStatus: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'GenericList/getStatussForTech',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    searchStatusForACL: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Status/getallforacl',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    searchResponseType: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/getresponsetypes',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    searchRequestType: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/getrequesttypes',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getSurveyList: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/survey/getlist/'+ state.selectedJobCard.ticketId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    // timesheets
    searchSubmissionsForManager: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Timesheet/SearchSubmittedListForManager',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchSubmissionsRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    denyTimesheet: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Timesheet/reject',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.completeTimesheetRequest
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage', 'Timesheet updated!')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an error completing your request. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    approveTimesheet: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Timesheet/approve',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.completeTimesheetRequest
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage', 'Timesheet updated!')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an error completing your request. Please refresh and try again'})
              reject(err)
            })
      })
    },

    downloadWeeklySubmissionReport:  ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'Timesheet/downloadWeeklyTimeSheet/' + state.selectedTimesheet.submissionID,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getSubmissionDetails:  ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'Timesheet/viewSubmitted/' + state.selectedTimesheet.submissionID,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    submitTimesheet: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/submit/'+ state.getForSubmissionRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        dispatch('notificationService/addSuccessMessage', 'Timesheet submitted!')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not load data for submission. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getForSubmission: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/getForSubmission/'+ state.getForSubmissionRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not load data for submission. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getWeekEndings: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/GetWeekEndings',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not load week endings. Please refresh and try again'})
        reject(err)
      })
      })
    },
    getAllSubmissions: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/GetSubmittedList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not load previous submissions. Please refresh and try again'})
        reject(err)
      })
      })
    },

    deleteTimesheetEntry: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/delete/' + state.deleteTimesheetEntryRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not delete enrty. Please refresh and try again'})
        reject(err)
      })
      })
    },
    loadWorkTypes: ({state, dispatch}, subtype) =>{

      console.log(subtype)

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/getWorkTypes/' + subtype ,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    loadDate: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Timesheet/forDate/' + state.loadDateRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    
    downloadEffiecencyReport: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Timesheet/downloadEffeicenyReport',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.downloadRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    getSummary: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Timesheet/getSumary',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.getSummaryRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    saveTimesheet: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Timesheet/save',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.saveTimesheetRequest
      };
      
      axios(callConfig)
      .then(response => {
        dispatch('notificationService/addSuccessMessage', 'Added!')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    

    // quotes 
    searchQuotes: ({state, dispatch}) =>{
      let payload = state.quoteSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'BudgetaryQuote/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    exportQuoteSummary: ({state, dispatch}) =>{
      let payload = state.quoteSummaryRequest
      
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'BudgetaryQuote/exportQuoteSummary',
        headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };
        axios(callConfig)
          .then(response => {
            resolve(response)
            })
        .catch(err => {
      
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
        })
      })
    },
    
    getQuoteSummary: ({state, dispatch}) =>{
      let payload = state.quoteSummaryRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'BudgetaryQuote/GetSummary',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getBusinessUnits: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'User/Admin/GetNextecCompanyList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getQuotes: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'BudgetaryQuote/get/' + state.selectedQuote.budgetaryQuoteId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedQuote = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getQuoteTypeList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'BudgetaryQuote/getQuoteTypeList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not download file from server'})
        reject(err)
      })
      })
    },

    createQuote: ({state, dispatch}) => {
      let payload = state.quoteCreateRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'BudgetaryQuote/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not create new data. Please try again'})
        reject(err)
      })
      })
    },
    
    getStatusList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'BudgetaryQuote/getStatusList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'Could not download file from server'})
        reject(err)
      })
      })
    },

    updateQuote: ({state, dispatch}) => {
      let payload = state.selectedQuote

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'BudgetaryQuote/update',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        
        if (response.data === false) {
          dispatch('notificationService/addErrorMessage', {error: '', message: 'An error occured while trying to save the data'})
        }else{
          dispatch('notificationService/addSuccessMessage', 'Quote saved')
        }
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
    })
    },
    
    downloadQuote: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        //console.log(state.baseUrl + 'BudgetaryQuote/ExportQuote/' + state.selectedQuote.budgetaryQuoteId)
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'BudgetaryQuote/ExportQuote/' + state.selectedQuote.budgetaryQuoteId,      
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'Could not download file from server'})
        reject(err)
      })
      })
    },
    
    // contacts
    searchContactsRequest: ({state, dispatch}) =>{
      let payload = state.contactSearchRequest
      
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ClientContact/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            
            dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
            reject(err)
          })
      })
    },
    
    getContactDetail: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'ClientContact/get/' + state.selectedContact.clientContactId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              state.contactDetailRequest = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    createContact: ({state, dispatch}) => {
      let payload = state.contactCreateRequest

      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ClientContact/create',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              if (response.data === false){
                dispatch('notificationService/addErrorMessage', {error: '', message: 'An error occured while trying to save the data'})
              }
              resolve(response)
              
            })
            .catch(err => {
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'Could not create new data. Please try again'})
              reject(err)
            })
        
      })
    },

    updateContact: ({state, dispatch}) => {
      let payload = state.selectedContact
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'post',
          url: state.baseUrl + 'ClientContact/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage', 'Contact updated')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },

    deleteContact: ({state, dispatch}) => {

      let payload = state.selectedContact
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ClientContact/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'Contact deleted')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },


    // checklist schedule
    searchScheduledChecklists: ({state, dispatch}) =>{
      let payload = state.checklistScheduledSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckListSchedule/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    createNewSchedule: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckListSchedule/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedScheduledChecklist
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    updateNewSchedule: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckListSchedule/update',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedScheduledChecklist
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getScheduleChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/get/' + state.selectedScheduledChecklist.checkListScheduleID,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
        }
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedScheduledChecklist = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getScheduleCompanies: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/getCompany',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getScheduleCompaniesList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/getCompanyList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    deleteChecklistSchedule: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/removeSchedule/' + state.deleteScheduleChecklistRequest,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
        }
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Shedule successfully deleted')
        
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue delete the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getTodoList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/getToDoList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue delete the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getScheduledChecklistsForList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckListSchedule/getScheduleForList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },


    // checklist
    searchAllTicketCompletedChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'jobCard/checklist/searchResults',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    

    searchAllCompletedChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/checklist/searchResultsAll',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    searchCompletedChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/checklist/searchResults',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getTicketChecklists: ({state, dispatch}) =>{

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'jobCard/checklist/getlist/' + state.ticketId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedJobCard.checkLists = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    uploadAnswerImage: ({state, dispatch}) =>{
      let payload = state.uploadAnswerImageRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/checklist/SaveAnswerImage',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue uploading the image. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    uploadSurveyAnswerImage: ({state, dispatch}) =>{
      let payload = state.uploadAnswerImageRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'SurveyEx/SaveAnswerImage',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue uploading the image. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    downloadAllCompletedChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/checklist/downloadCompletedChecklistsOpen',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    downloadCompletedChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/checklist/downloadCompletedChecklists',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    downloadUserChecklistResponse: ({state, dispatch}) =>{      

      if (state.completedChecklist.pkTicketCheckListId != undefined)
      {      
        
        return new Promise((resolve, reject) =>{
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Jobcard/checklist/downloadCompletedChecklist/' + state.completedChecklist.pkTicketCheckListId ,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };
        
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
        })
      }
      else if(state.completedChecklist.isTicketChecklist === false)
      {
        return new Promise((resolve, reject) =>{

          console.log(state.completedChecklist)
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/checklist/downloadCompletedChecklist/' + state.completedChecklist.entityCheckListId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };
        
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
        })
      }
    },

    getUserChecklistResponse: ({state, dispatch}) =>{
      if (state.completedChecklist.isTicketChecklist==true)
      {
        return new Promise((resolve, reject) =>{
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Jobcard/checklist/getCompletedChecklist/' + state.completedChecklist.entityCheckListId ,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };
        
        axios(callConfig)
        .then(response => {
          console.log('response',response)
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
        })
      }
      else
      {
        return new Promise((resolve, reject) =>{
          var callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/checklist/getCompletedChecklist/' + state.completedChecklist.entityCheckListId ,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };
        
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          
          dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
          reject(err)
        })
        })
      }
    },

    getChecklistTypes: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/CheckListTypes' ,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the checklist types. Please refresh and try again'})
        reject(err)
      })
      })
    },

    searchChecklists: ({state, dispatch}) =>{
      let payload = state.checklistSearchRequest

      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getChecklists: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/getall/CheckList/enabled',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'There was an issue loading the checklists. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getToolboxTalks: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/getall/Toolbox Talk/enabled',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'There was an issue loading the checklists. Please refresh and try again'})
        reject(err)
      })
      })
    },
    
    getToolboxTalksForUser: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'User/checklist/GetOutstandingChecklists/Toolbox Talk',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'There was an issue loading the checklists. Please refresh and try again'})
        reject(err)
      })
      })
    },


    getChecklistInformation: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/get/' + state.selectedChecklist.checkListId,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedChecklist = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not retrieve checklist information. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    
    createChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/create',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.checklistCreateRequest
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedChecklist = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not create checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    
    updateChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/update',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedChecklist
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Checklist updated')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    deleteChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/DeleteCheckList',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedChecklist
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Checklist deleted')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not delete checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    deleteCategory: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/DeleteCategory',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.deleteCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Category deleted')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not delete checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    updateCategory: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/SaveCategory',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.newCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    updateQuestion: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'CheckList/SaveQuestion',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.questionForCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    getQuestionAnswerTypes: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/QuestionAnswerTypes',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not retrieve answer types. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    saveUserChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'user/checklist/Add',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.userChecklistRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    saveJobCardChecklist: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: `${state.baseUrl}JobCard/checklist/Add`,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.jobcardChecklistRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    // admin 
    searchProducts: ({state, dispatch}) =>{
      let payload = state.productSearchRequest
      return new Promise((resolve, reject) =>{
        const callConfig = {
        method: 'post',
        url: state.baseUrl + 'PriceBook/search',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
        reject(err)
      })
      })
    },

    getProductDetail: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PriceBook/get/' + state.selectedProduct.priceBookId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              state.productDetailRequest = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    updateProduct: ({state, dispatch}) => {
      let payload = state.selectedProduct
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PriceBook/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'Product updated')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },
    
    createProduct: ({state, dispatch}) => {
      let payload = state.productCreateRequest

      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PriceBook/create',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              if (response.data === false){
                dispatch('notificationService/addErrorMessage',{error:  '', message: 'An error occured while trying to save the data'})
              }
              dispatch('notificationService/addSuccessMessage', 'Product Added')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not create new data. Please try again'})
              reject(err)
            })
      })
    },
    
    deleteProduct: ({state, dispatch}) => {

      let payload = state.selectedProduct
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PriceBook/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'Product deleted')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },
    
    //user
    
    downloadUserList: ({state, dispatch}) => {
      let payload = state.userSearchRequest
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'User/export',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: payload,
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message: 'There was an issue downloading the document. Please refresh and try again'})
        reject(err)
      })
    })
    },

    searchUsersRequest: ({state, dispatch}) =>{
      let payload = state.userSearchRequest
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    searchSupervisorsRequest: ({state, dispatch}) =>{
      let payload = state.userSearchRequest
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/Admin/GetSupervisors',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getUserDetail: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/get/' + state.selectedUser.userId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              state.selectedUser = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getRegionList: ({state, dispatch}) => {
      console.log(state.selectedUser);
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',

          url: state.baseUrl + 'User/Admin/GetNextecRegionList/' + state.selectedUser.nextecCompanyId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    getRegionListForACL: ({state, dispatch}) => {
      //console.log(state.selectedUser);
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',

          url: state.baseUrl + 'User/Admin/GetNextecRegionList/' + state.regionListRequest,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    getSupervisors: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/Admin/GetSupervisors/0/0',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getOfficeListForACL: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/Admin/GetNextecOfficeList/' + state.officeListRequest,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getOfficeList: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/Admin/GetNextecOfficeList/' + state.selectedUser.nextecCompanyId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    getCompanyList: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/Admin/GetNextecCompanyList',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getAccessModules: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/Admin/GetAccessModules',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    updateUser: ({state, dispatch}) => {
      let payload = state.selectedUser
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'User updated')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message:'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },

    createUser: ({state, dispatch}) => {
      let payload = state.userCreateRequest
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/create',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
                if (response.data.result === 0) {
                    reject();
                    dispatch('notificationService/addErrorMessage', { error: '', message: 'User already exist.' });
                } else {
                    dispatch('notificationService/addSuccessMessage', 'User Added');
                    resolve(response);
                }
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not create new data. Please try again'})
              reject(err)
            })
      })
    },

    deleteUser: ({state, dispatch}) => {

      let payload = state.selectedUser
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'User deleted')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not update checklist. Please try again or contact server administrator'})
              reject(err)
            })
      })
    },

    getUserRoles: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'User/GetUserRoles',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          },
        };

        axios(callConfig)
            .then(response => {
              state.userRoleRequest = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    createNewPassword: ({state, dispatch}) => {
      let payload = state.userPasswordRequest
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/resetPassword/' + state.selectedUser.userId, // this will have to be updated to the right call
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage', 'User Password Updated')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not create new data. Please try again'})
              reject(err)
            })
      })
    },
    
    //acl triggers

    searchAclTriggers: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ACLtrigger/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchAclTriggerRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    

    downloadAclTriggers: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ACLtrigger/exportlist',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchAclTriggerRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    createAclTriggers: ({state, dispatch}) => {
      //console.log('trigger',state.selectedTrigger)
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ACLtrigger/create',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.selectedTrigger
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage','Trigger created successfully.')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    updateAclTriggers: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'ACLtrigger/update',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.selectedTrigger
        };

        axios(callConfig)
            .then(response => {
              
              dispatch('notificationService/addSuccessMessage','Trigger saved successfully.')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getContractTypes: ({state, dispatch}) => {
      //console.log('selected company', state.selectedTrigger)
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'Company/GetContracts/' + state.selectedTrigger.company.id,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    getContractTypesByLocation: ({state, dispatch}) => {
      //console.log('selected company', state.selectedTrigger)
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'company/GetConttractsByLocation/' + state.selectedTrigger.locationId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              state.contractTypes = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the contracts. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getCallTypes: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'GenericList/getCallTypes',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getOrigins: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'GenericList/getOrigins',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getTrigger: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'ACLtrigger/get/' + state.selectedTrigger.acltriggerId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              state.selectedTrigger = response.data
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    // getTechBasedOnGroupAndRequestType: ({state, dispatch}) => {
    //   return new Promise((resolve, reject) =>{
    //     var callConfig = {
    //       method: 'get',
    //       url: state.baseUrl + 'Technician/GetTechniciansByGroupLocation/'+ state.selectedTrigger.technitianGroupId + '/' + state.selectedTrigger.locationId,
    //       headers: {
    //         'Authorization': 'Bearer '+ localStorage.getItem('jwt')
    //       }
    //     };

    //     axios(callConfig)
    //         .then(response => {
    //           resolve(response)
    //         })
    //         .catch(err => {
    //           
    //           dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
    //           reject(err)
    //         })
    //   })
    // },

    getPriorityForTriggers: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'GenericList/getPriorities',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    getRequestTypes: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        var callConfig = {
          method: 'get',
          url: state.baseUrl + 'GenericList/getTicketRequestTypes/' + state.requestTypesRequest,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    
    //reports
    downloadComplianceReport: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/checklist/downloadTasksProgressReport',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.downloadRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    downloadCompanyComplianceReport: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'User/checklist/downloadToolboxTalkSummaryReport',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.downloadRequest
        }; 

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    

    downloadIndividualSummaryReport: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'timesheet/downloadWeeklyTimeSheet',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.downloadRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    

    //surveys
    searchSurveys: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Survey/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchSurveysRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    createSurvey: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'Survey/create',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.createSurveyRequest
        };

        axios(callConfig)
            .then(response => {
              state.selectedSurvey = response.data.result
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    getSurveyInformation: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Survey/get/' + state.selectedSurvey.surveyID,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        state.selectedSurvey = response.data
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not retrieve survey information. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    
    updateSurvey: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/update',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedSurvey
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Survey updated')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update survey. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    deleteSurvey: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/DeleteSurvey',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.selectedSurvey
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Survey deleted')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not delete survey. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    deleteSurveyCategory: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/DeleteCategory',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.deleteCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        
        dispatch('notificationService/addSuccessMessage', 'Category deleted')
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not delete checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    updateSurveyCategory: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/SaveCategory',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.newCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not update checklist. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    updateSurveyQuestion: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/SaveQuestion',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.questionForCategoryRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not update survey. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    downloadSurveyResponseReport: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Survey/DownloadReport',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
          'Content-Type': 'application/json'
        },
        data: state.downloadSurveyRequest
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(), message:'Could not download survey. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },
    getSurveyQuestionAnswerTypes: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'Survey/QuestionAnswerTypes',
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not retrieve answer types. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    getSurveyByKey: ({state, dispatch}) =>{
      
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'SurveyEx/GetSurvey/' + state.surveyKey,
        headers: {
          'Authorization': null
        },
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not retrieve answer types. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    saveSurvey: ({state}) =>{
      let payload = state.saveSurveyRequest
      console.log('Payload',payload)
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'SurveyEx/SaveSurvey/' + state.surveyKey,
        headers: {
          'Authorization': null,
          'Content-Type': 'application/json'
        },
        data: payload
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
       // dispatch('notificationService/addErrorMessage', {error: err.toString(),message: 'Could not retrieve answer types. Please try again or contact server administrator'})
        reject(err)
      })
      })
    },

    //XLogs logger
    searchXLog: ({state, dispatch}) =>{
      let payload = state.searchXLogRequest

      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'XLog/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage', {error: err.toString(), message:'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    //Event logger
    searchEvent: ({state, dispatch}) => {
      let payload = state.searchEventRequest

      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'History/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: payload,
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    //PPE Requests
    getAllRequests: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/getall',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getAvailableItems: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/GetAvailableItems',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    createNewRequest: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/create/0',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.newPpeRequestRequest,
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage','New PPE Request created. Keep an eye on your inbox for notifications')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    
    createNewRequestFor: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/create/' + state.newPpeRequestRequest.createdFor,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.newPpeRequestRequest,
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage','New PPE Request created. Notifications has been sent')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    setRequestAsCollected: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/collect/' + state.selectedPpeRequest.ppErequestId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage','Completed! Stay safe.')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    setRequestToReadyForPickup: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/ReadyForPickup',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.selectedPpeRequest,
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage','Marked as ready. Requestee notified.')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    approvePpeRequest: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/approve',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.selectedPpeRequest,
        };

        axios(callConfig)
            .then(response => {
              dispatch('notificationService/addSuccessMessage','Request has been acknowledged. Adjustments has been made.')
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    
    getTop10: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/getTop10/' + state.selectedPpeRequest.ppErequestId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    searchPpeRequests: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/search',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchPpeRequestsRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not retrieve the data the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    updateAvailableItems: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'PPErequest/EditAvailableItems',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.searchUpdateAvailableItemsRequest
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not retrieve the data the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getPpeRequest: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/get/' + state.selectedPpeRequest.ppErequestId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              state.selectedPpeRequest = response.data.result
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getPpeRequestForDisplay: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'PPErequest/get/' + state.ppeRequestIdRequest,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    addTravelToTicket: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'JobCard/AddTravel/' + state.ticketId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.travelItem
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not retrieve the data the data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    getTravelForTicket: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'get',
          url: state.baseUrl + 'JobCard/getTravel/' + state.ticketId,
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt')
          }
        };

        axios(callConfig)
            .then(response => {
              console.log("esponse.data",response.data)
              state.selectedJobCard.equipmentUsed = response.data
              console.log("state.selectedJobCard.equipmentUsed",state.selectedJobCard.equipmentUsed)
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the data. Please refresh and try again'})
              reject(err)
            })
      })
    },
    
    GetCheckListSherqMailList: ({state, dispatch}) =>{
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'get',
        url: state.baseUrl + 'CheckList/GetCheckListSherqMailList' ,
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('jwt')
        }
      };
      
      axios(callConfig)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        
        dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'There was an issue loading the checklist Sherq Mail List. Please refresh and try again'})
        reject(err)
      })
      })
    },

    AddSherqMailList: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'CheckList/AddSherqMailList',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.mailList
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not save mail list data. Please refresh and try again'})
              reject(err)
            })
      })
    },

    DeleteSherqMailList: ({state, dispatch}) => {
      return new Promise((resolve, reject) =>{
        const callConfig = {
          method: 'post',
          url: state.baseUrl + 'CheckList/DeleteSherqMailList',
          headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
            'Content-Type': 'application/json'
          },
          data: state.mailList
        };

        axios(callConfig)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              
              dispatch('notificationService/addErrorMessage',{error:  err.toString(),message: 'Could not save mail list data. Please refresh and try again'})
              reject(err)
            })
      })
    },


  },
  modules: {
  }
})
