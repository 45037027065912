<template>
    <div>
        <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
            <b-col cols="12" md="3">
                <b-card class="mb-2">
                    <b-card-header>
                        <img src="@/assets/ERGO_Final_NEXTEC.svg" alt="" class="img-to-fit">
                    </b-card-header>
                    <b-card-text>
                        <b-row class="mb-0 px-0">
                        <b-col cols="12" class="px-0 mb-2"  v-for="(item, index) in messages" :key ="index">
                            <b-alert :show="dismissSecs" dismissible
                         @dismiss-count-down="countDownChanged" :variant="item.type">{{item.message}}</b-alert>
                        </b-col>
                            <b-col cols="12" class="px-0">
                                    <label>Username</label>
                                    <b-form-input v-model="username"></b-form-input>
                            </b-col>
                            <b-col cols="12" class="px-0 mt-2">
                                    <label>Password</label>
                                    <b-form-input v-model="password" type="password"
                                    @keyup.enter="loginUser()"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0">
                            <b-button class="mt-3 primary" @click="loginUser()" size="sm" :variant="filled" block squared>
                                <b-spinner small v-if="isLoading"></b-spinner>
                                Login
                            </b-button>
                        </b-row>
                        <b-row class="mx-0">
                            <b-button class="mt-3 primary-no-border" @click="forgotPassword()" size="sm" variant="primary" block squared>
                                Forgot Password?
                            </b-button>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import md5 from 'js-md5'
import notificationService from '@/store/notificationService'

export default {
    components: {
    },
    data: () => ({
        state: 'login',
    dismissSecs: 10,
    dismissCountDown: 0,
        showErrors: false, 
        errors: [],
        username: null,
        password: null,
        email: null,
        isLoading: false
    }),
    mounted() {
        this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
    },
    beforeCreate() {
        if (!this.$store.hasModule('notificationService')) {
            this.$store.registerModule('notificationService', notificationService);
        }

    },
    created(){
        
        this.clearMessage()
    },
    methods:{
        ...mapActions(['login']),
        ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage', 'clearMessage']),
        forgotPassword(){
            this.$router.push({path: '/forgot'})
        },
        loginUser() {
            this.isLoading = true
            this.showErrors = false
            var bytes = []; // char codes

            for (var i = 0; i < this.password.length; ++i) {
                var code = this.password.charCodeAt(i);
                bytes = bytes.concat([code]);
            }

            let passwordhash = md5.base64(bytes)

            let request = {
                email: this.username,
                password: passwordhash
            }

            this.$store.commit('setLoginRequest', request)

            this.login()
            .then((res) =>{
                console.log("Result",res)
                this.isLoading = false
                this.$router.push({path: '/home'})
            })
            .catch(() => {
                this.isLoading = false
            })
        },
        
        hideVerificationEmailModal(){
            this.$refs['email-verification-modal'].hide()
        },
        showVerificationEmailModal(){
            this.$refs['email-verification-modal'].show()
        },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      this.dismissCountDown = this.dismissSecs
    },
    },
    computed:{
        ...mapState('notificationService', ['messages']),
         filled(){
             if (this.username !== null && this.password !== null && this.username !== '' && this.password !== '') {
                return 'primary'
             }
             else{
                 return 'primary'
             }
         }
    }
}
</script>

