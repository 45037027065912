import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: '/setPassword/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: '/signUp',
    name: 'SignUpStep1',
    component: () => import('../views/SignUpStep1.vue'),
    meta: {
        requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/OuterView.vue'),
    children: [
      {
        path: '/home', name: 'home', component: () => import('../views/Home.vue'),
        meta: {
            requiresAuth: true
        }
      },
      
      //job cards
      {
        path: '/jobCards/search', name: 'searchjobCards', component: () => import('../views/jobCards/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/jobCards/view', name: 'viewjobCards', component: () => import('../views/jobCards/View.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/jobCards/quoteView', name: 'viewJobCardQuote', component: () => import('../views/jobCards/Quote.vue'),
        meta: {
            requiresAuth: true
        }
      },


      //reports
      {
        path: '/complianceReport', name: 'complianceReport', component: () => import('../views/reports/complianceReport.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/allchecklist/completed', name: 'allCompletedChecklists', component: () => import('../views/reports/allCompletedChecklist.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/individualReport', name: 'individualReport', component: () => import('../views/reports/timesheetIndividualBreakdown.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/groupReport', name: 'groupReport', component: () => import('../views/reports/timesheetSummaryReport.vue'),
        meta: {
          requiresAuth: true
        }
      },

      //self service
      {
        path: '/selfService/search/admin', name: 'searchSelfService', component: () => import('../views/selfService/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/selfService/search', name: 'searchSelfService', component: () => import('../views/selfService/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/selfService/add', name: 'addSelfService', component: () => import('../views/selfService/Add.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/selfService/view', name: 'viewSelfServiceRequestDetail', component: () => import('../views/selfService/View.vue'),
        meta: {
            requiresAuth: true
        }
      },

      //quotes
      {
        path: '/quotes/search', name: 'searchQuotes', component: () => import('../views/quotes/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/quotes/view', name: 'viewQuotes', component: () => import('../views/quotes/View.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/quotes/summary', name: 'summaryQuotes', component: () => import('../views/quotes/SummaryView.vue'),
        meta: {
            requiresAuth: true
        }
      },
      //contacts
      {
        path:'/contacts/contactSearch', name: 'searchContacts', component: () => import('../views/contacts/contactSearch'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/contacts/contactView', name: 'contactView', component: () => import('../views/contacts/contactView'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/contacts/createContact', name: 'createContact', component: () => import('../views/contacts/createContact'),
        meta: {
          requiresAuth: true
        }
      },

      //timesheets
      {
        path:'/timesheets/view', name: 'viewTimesheet', component: () => import('../views/timesheets/timesheetToday.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/submitTimesheetList', name: 'submitTimesheetList', component: () => import('../views/timesheets/submitTimesheetListSubmitee.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/submitTimesheet', name: 'submitTimesheet', component: () => import('../views/timesheets/submitTimesheetAdd.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/viewSubmission', name: 'viewSubmissionTimesheet', component: () => import('../views/timesheets/submitTimesheetViewSubmitee.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/listSubmissions', name: 'listSubmissions', component: () => import('../views/timesheets/submitTimesheetListReviewer.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/timesheets/completeSubmission', name: 'completeSubmissionTimesheet', component: () => import('../views/timesheets/submitTimesheetViewReviewer.vue'),
        meta: {
          requiresAuth: true
        }
      },
      
      //checklists
      {
        path: '/checklist/search', name: 'searchChecklists', component: () => import('../views/checklists/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/checklist/manage', name: 'manageChecklists', component: () => import('../views/checklists/ManageChecklists.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/checklist/complete', name: 'completeChecklists', component: () => import('../views/checklists/DoChecklist.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/checklist/completeToolBox', name: 'completeToolbox', component: () => import('../views/checklists/DoToolbox.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/checklist/completed', name: 'completedChecklists', component: () => import('../views/checklists/completedChecklist.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/checklist/completedView', name: 'completedChecklistsView', component: () => import('../views/checklists/completedChecklistView.vue'),
        meta: {
          requiresAuth: true
        }
      },

      //schedule checklist
      {
        path: '/checklist/schedule/search', name: 'scheduleChecklistSearch', component: () => import('../views/checklists/ScheduleChecklistSearch.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/checklist/schedule/create', name: 'scheduleChecklistCreate', component: () => import('../views/checklists/ScheduleChecklistCreate.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/checklist/schedule/update', name: 'scheduleChecklistUpdate', component: () => import('../views/checklists/ScheduledChecklistUpdate.vue'),
        meta: {
          requiresAuth: true
        }
      },

      //ppe request search
      {
        path: '/ppe/history', name: 'ppeRequestHistory', component: () => import('../views/ppeRequest/viewPpeRequestHistory.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/create', name: 'ppeRequestCreate', component: () => import('../views/ppeRequest/newPpeRequest.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/create/for', name: 'ppeRequestCreateFor', component: () => import('../views/ppeRequest/createNewPpeRequestForView.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/view', name: 'ppeRequestView', component: () => import('../views/ppeRequest/viewRequestedPpeRequestee.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/edit', name: 'ppeRequestEdit', component: () => import('../views/ppeRequest/editPpeItems.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/current/search', name: 'ppeRequestCurrentSearch', component: () => import('../views/ppeRequest/currentPpeRequest.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ppe/current/view', name: 'ppeRequestCurrentView', component: () => import('../views/ppeRequest/currentPpeRequestView.vue'),
        meta: {
          requiresAuth: true
        }
      },

      //survey
      {
        path: '/survey/search', name: 'searchSurvey', component: () => import('../views/surveys/Search.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/survey/manage', name: 'manageSurvey', component: () => import('../views/surveys/View.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/survey/summary', name: 'summary', component: () => import('../views/surveys/summary'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/completeSurvey/:key', name: 'completeSurvey', component: () => import('../views/surveys/CompleteSurvey.vue'),
        meta: {
          requiresAuth: false
        }
      },

      //admin
      {
        path: '/admin/StockSearch', name: 'StockSearch', component: () => import('../views/admin/StockSearch.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/admin/stockView', name: 'stockView', component: () => import('../views/admin/stockView.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/admin/createProduct', name: 'createProduct', component: () => import('../views/admin/createProduct'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/admin/userSearch', name: 'userSearch', component: () => import('../views/admin/userSearch'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/admin/sherqMailList', name: 'sherqMailList', component: () => import('../views/admin/sherqMailList'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/admin/userView', name: 'userView', component: () => import('../views/admin/userView'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/admin/userCreate', name: 'userCreate', component: () => import('../views/admin/userCreate'),
        meta: {
          requiresAuth: true
        }
      },

      //super admin
      {
        path: '/exceptionLogger', name: 'exceptionLogger', component: () => import('../views/superAdmin/ExceptionLogger.vue'),
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/eventLogger', name: 'eventLogger', component: () => import('../views/superAdmin/EventLogger.vue'),
        meta: {
            requiresAuth: true
        }
      },
      
      //ACL triggers 
      {
        path: '/acl/triggerSearch', name: 'aclTriggerSearch', component: () => import('../views/aclTrigger/Search'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/acl/triggerView', name: 'aclTriggerView', component: () => import('../views/aclTrigger/View'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/acl/triggerCreate', name: 'aclTriggerCreate', component: () => import('../views/aclTrigger/Create'),
        meta: {
          requiresAuth: true
        }
      },
      
      //scoming soon
      {
        path: '/comingsoon', name: 'comingSoon', component: () => import('../views/ComingSoon.vue'),
        meta: {
            requiresAuth: true
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {

    if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt') !== '') {
      next()
    }
    else{
      if (to.name != 'Login') {
        next('')
      } else {
        next()
      }
    }

  } else {
    next()
  }
})


export default router
