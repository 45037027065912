<template>
    <div>

        <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
            <b-col cols="12" md="3">
                <b-card class="mb-2">
                    <b-card-header>
                        <img src="@/assets/ERGO_Final_NEXTEC.svg" alt="" class="img-to-fit">
                    </b-card-header>
                    <b-card-text>
                        <b-row class="mb-0 px-0">
                        <b-col cols="12" class="px-0 mb-2"  v-for="(item, index) in messages" :key ="index">
                            <b-alert show dismissible :variant="item.type">{{item.message}}</b-alert>
                        </b-col>
                            <b-col cols="12" class="px-0 mt-2">
                                    <label>Password</label>
                                    <b-form-input v-model="password" type="password"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0">
                            <b-button class="mt-3 primary" @click="reset()" size="sm" variant="primary" block squared>
                                <b-spinner small v-if="isLoading"></b-spinner>
                                Set Password
                            </b-button>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

    </div>

</template>

<script>
import notificationService from '@/store/notificationService'
import { mapActions, mapState } from 'vuex'
import md5 from 'js-md5'

export default {
    data: () => ({
        state: 'login',
        showErrors: false, 
        errors: [],
        username: null,
        password: null,
        isLoading: false
    }),
    beforeCreate() {
        if (!this.$store.hasModule('notificationService')) {
        this.$store.registerModule('notificationService', notificationService);
        }
    },
    methods:{
        ...mapActions(['resetPassword']),
        ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage', 'clearMessage']),
        reset() {
            this.isLoading = true
            this.showErrors = false
            this.errors = []
            //set payload
            var bytes = []; // char codes

            for (var i = 0; i < this.password.length; ++i) {
                var code = this.password.charCodeAt(i);
                bytes = bytes.concat([code]);
            }

            let passwordhash = md5.base64(bytes)

            let request = {
                "key": this.$route.params.token,
                "value": passwordhash
            }
            this.$store.commit('setResetPasswordRequest', request)
            this.resetPassword()
            .then((res) => {
                //console.log(res)
                this.isLoading = false
                if (res.data === false) {
                    this.clearMessage()
                    this.addErrorMessage({error: '', message:'Could not reset password'})
                } else {
                    this.$router.push({path: '/'})
                }
            })
            .catch(() => {
                this.isLoading = false
                this.password = null
            })
        }
    },
    computed:{
        ...mapState('notificationService', ['messages'])
    }
}
</script>