<template>
    <div>
        <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
            <b-col cols="12" md="3">
                <b-card class="mb-2">
                    <b-card-header>
                        <img src="@/assets/ERGO_Final_NEXTEC.svg" alt="" class="img-to-fit">
                    </b-card-header>
                    <b-card-text>
                        <b-row class="mb-0 px-0" v-if="!isEmailSent">
                        <b-col cols="12" class="px-0 mb-2"  v-for="(item, index) in messages" :key ="index">
                            <b-alert show dismissible :variant="item.type">{{item.message}}</b-alert>
                        </b-col>
                            <b-col cols="12" class="px-0">
                                    <label>Username</label>
                                    <b-form-input v-model="username"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0" v-if="!isEmailSent">
                            <b-button class="mt-3 primary" @click="reset()" size="sm" variant="primary" block squared >
                                <b-spinner small v-if="isLoading"></b-spinner>
                                Send Reset Password
                            </b-button>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0" v-if="isEmailSent">
                            <div  style="text-align:center" >Your request to have your password reset has been processed.<br>
                                If your username is valid, you will receive an email with instructions on resetting your password.
                            </div>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import notificationService from '@/store/notificationService'

export default {
    components: {
    },
    data: () => ({
        showErrors: false, 
        errors: [],
        username: null,
        password: null,
        email: null,
        isEmailSent: false,
        isLoading: false
    }),
    beforeCreate() {
        if (!this.$store.hasModule('notificationService')) {
        this.$store.registerModule('notificationService', notificationService);
        }
    },
    methods:{
        ...mapActions(['sendRequestToResetPassword']),
        reset(){
            let request = {
                "email": this.username,
                "password": ""
            }
            this.$store.commit('setResetPasswordRequest', request)
            this.isLoading = true
            this.sendRequestToResetPassword()
            .then(() => {
                this.isLoading = false
                this.isEmailSent = true
            })
            .catch((err) => {
                this.isLoading = false
                console.log("Error",err)
            })
        }
    },
    computed:{
        ...mapState('notificationService', ['messages'])
    }
}
</script>

